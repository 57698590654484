<template>
  <v-container>
    <AddTemplatePathDialog
      :paths="filePaths"
      :isOpen="isNewFolderDialog"
      @filesAdded="handleFilesAdded"
      @clearUploadedImage="handleClearUploadedDesignLiveImage"
      @saveNewPath="saveNewPath"
      @onClose="closeNewFolderDialog"
    >
    </AddTemplatePathDialog>

    <v-row>
      <v-col>
        <v-btn icon class="mb-2" @click.native="back()">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
        <span class="headline pt-1">{{ getHeaderText }}</span>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-card>
      <v-form v-model="isFormValid" class="mt-3">
        <v-card-title tag="span" class="primary--text ml-2"
          >Template Information <v-chip v-if="!isSiteDefault" :color="templateInfoColor" class="ml-2"> {{ templateInfoType }} </v-chip></v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-row class="mb-n8">
                  <v-col cols="6">
                    <v-text-field
                      v-model="template.name"
                      :rules="required"
                      :disabled="!isEditable"
                      label="Template Name"
                      hint="Enter Template Name"
                      outlined
                      dense
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="template.linked_product"
                      :items="mappedProducts"
                      :disabled="!isEditable"
                      item-text="name"
                      item-value="id"
                      label="Linked Product"
                      prepend-inner-icon="mdi-magnify"
                      outlined
                      dense
                      type="text"
                      placeholder="Search..."
                      :persistent-placeholder="true"
                      :rules="required"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>

              <v-col>
                <v-row>
                  <v-col cols="6">
                    <TemplateFileUpload
                      :templateName="'Template Design Front'"
                      :previewUrl="template?.preview_front_url"
                      :isDisabled="!isEditable"
                      @previewUploadedImage="handlePreviewUploadedFrontImage"
                      @clearUploadedImage="handleClearUploadedImage"
                    />
                    <v-row>
                      <v-col cols="10">
                        <v-autocomplete
                          v-model="template.dl_template_front"
                          class="mt-4"
                          :disabled="!isEditable"
                          :items="designLivePaths"
                          item-text="name"
                          label="Design Live Template Front"
                          return-object
                          required
                          :rules="required"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2" class="d-flex align-center justify-center mb-3">
                        <v-btn color="gray" :disabled="!isEditable" @click="openNewFolderDialog(true)">
                          <v-icon class="d-flex align-center justify-center mr-n1" left>mdi-plus</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <TemplateFileUpload
                      :templateName="'Template Design Back'"
                      :previewUrl="template?.preview_back_url"
                      :isDisabled="!isEditable"
                      @previewUploadedImage="handlePreviewUploadedBackImage"
                      @clearUploadedImage="handleClearUploadedImage(false)"
                    />
                    <v-row>
                      <v-col cols="10">
                        <v-autocomplete
                          v-model="template.dl_template_back"
                          class="mt-4"
                          :items="designLivePaths"
                          :disabled="!isEditable"
                          item-text="name"
                          label="Design Live Template Back"
                          return-object
                          required
                          :rules="required"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2" class="d-flex align-center justify-center mb-3">
                        <v-btn color="gray" :disabled="!isEditable" @click="openNewFolderDialog(false)">
                          <v-icon class="d-flex align-center justify-center mr-n1" left>mdi-plus</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12 mt-n6">
                <v-divider></v-divider>
              </v-col>

              <v-col>
                <v-row class="mb-n8">
                  <v-col cols="6">
                    <v-text-field v-model="template.onebrand_id" label="OneBrand ID" :disabled="!isEditable" outlined dense> </v-text-field>
                    <v-autocomplete
                      v-model="template.type"
                      :items="mappedTemplateTypes"
                      :disabled="!isEditable"
                      item-text="name"
                      label="Type"
                      return-object
                      required
                      :rules="required"
                      outlined
                      dense
                    ></v-autocomplete>
                    <v-autocomplete
                      v-model="template.category"
                      :items="mappedTemplateCategories"
                      :disabled="!isEditable"
                      item-text="name"
                      label="Category"
                      return-object
                      required
                      :rules="required"
                      outlined
                      multiple
                      dense
                    ></v-autocomplete>

                    <v-text-field v-model="template.template_download_url" label="Template Download URL" :disabled="!isEditable" outlined dense> </v-text-field>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col cols="6" class="mt-n3">
                        <v-checkbox
                          v-for="box in checkBoxes.slice(0, 7)"
                          :key="box.key"
                          v-model="template[box.key]"
                          :label="box.label"
                          :disabled="!isEditable"
                          class="mb-n8"
                          @change="validateCheckboxes"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6" class="mt-n3">
                        <v-checkbox
                          v-for="box in checkBoxes.slice(7)"
                          :key="box.key"
                          v-model="template[box.key]"
                          :label="box.label"
                          :disabled="!isEditable"
                          class="mb-n8"
                          @change="validateCheckboxes"
                        ></v-checkbox>
                        <div v-if="!isAtLeastOneChecked" class="mt-4"><span class="red--text text--darken-4 caption">* at least one order process must be checked</span></div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="isEditable">
          <v-col class="text-right">
            <v-btn color="primary" class="white--text" :disabled="!isEveryFieldValid" @click="saveTemplate">Save Changes</v-btn>
          </v-col>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import TemplateFileUpload from './TemplateFileUpload.vue';
import AddTemplatePathDialog from '../dialog/AddTemplatePathDialog.vue';

export default {
  name: 'templates',
  components: {
    TemplateFileUpload,
    AddTemplatePathDialog
  },
  data() {
    return {
      template: {},
      checkBoxes: [],
      isFormValid: false,
      required: [v => !!v || 'This is a required field.'],
      uploadedFrontImage: null,
      uploadedBackImage: null,
      currentTemplateName: '',
      isAtLeastOneChecked: false,
      designList: [],
      products: [],
      isNewFolderDialog: false,
      selectedNewPath: '',
      newFolderName: '',
      designLiveArtwork: null,
      isFolderFront: true
    };
  },
  computed: {
    ...mapGetters({
      templateCategories: 'template_categories/getTemplateCategories',
      templateTypes: 'template_types/getTemplateTypes',
      siteId: 'templates/getSiteId',
      designLivePaths: 'templates/getDesignLivePaths'
    }),

    isEveryFieldValid() {
      return this.isFormValid && this.isAtLeastOneChecked && !!this.template.preview_front_url && !!this.template.preview_back_url;
    },

    mappedTemplateTypes() {
      return this.templateTypes.map(({ name, template_type_firebase_id }) => ({
        id: template_type_firebase_id,
        name
      }));
    },

    mappedTemplateCategories() {
      return this.templateCategories.map(({ name, template_category_firebase_id }) => ({
        id: template_category_firebase_id,
        name
      }));
    },

    mappedProducts() {
      return this.products.map(({ name, product_firebase_id }) => ({
        id: product_firebase_id,
        name
      }));
    },

    filePaths() {
      return this.designLivePaths
        .map(path => {
          const lastIndex = path.lastIndexOf('/');
          return path.substring(0, lastIndex);
        })
        .filter(path => path);
    },

    getHeaderText() {
      return this.$route.name === 'templatesNew' ? 'New template' : `${this.isEditable ? 'Edit' : 'View'}: ${this.currentTemplateName}`;
    },

    isTemplateDefault() {
      return this.template?.is_default;
    },

    templateInfoColor() {
      return this.isTemplateDefault ? 'primary' : 'secondary';
    },

    templateInfoType() {
      return this.isTemplateDefault ? 'Default' : 'Custom';
    },

    isSiteDefault() {
      return this.siteId === 'default';
    },

    isEditable() {
      return this.$route.name !== 'templatesView';
    },

    templateId() {
      return this.$route.params?.templateId;
    }
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch('products/getDefaultProducts'),
      this.$store.dispatch('template_categories/getAllTemplateCategories'),
      this.$store.dispatch('template_types/getAllTemplateTypes')
    ]);
    this.initData();
  },

  methods: {
    openNewFolderDialog(isFolderFront) {
      this.isFolderFront = isFolderFront;
      this.isNewFolderDialog = true;
    },

    closeNewFolderDialog() {
      this.isNewFolderDialog = false;
    },

    handleFilesAdded(file) {
      this.designLiveArtwork = file;
    },

    handleClearUploadedDesignLiveImage() {
      this.designLiveArtwork = null;
    },

    async saveNewPath(payload) {
      try {
        this.$store.dispatch('showLoader');
        const field_name = this.isFolderFront ? 'front_path' : 'back_path';
        const response = await this.$store.dispatch('templates/uploadDesignLiveImage', {
          file: this.designLiveArtwork,
          field_name,
          newFolderName: payload.newFolderName,
          selectedPath: payload.selectedNewPath
        });
        if (this.isFolderFront) {
          this.template.dl_template_front = response;
        } else {
          this.template.dl_template_back = response;
        }
      } catch (error) {
        console.error('Error while uploading a new path.');
      } finally {
        this.closeNewFolderDialog();
        this.$store.dispatch('hideLoader');
      }
    },

    async initData() {
      this.template = {
        name: '',
        onebrand_id: '',
        preview_front_url: '',
        preview_back_url: '',
        dl_template_back: '',
        dl_template_front: '',
        type: null,
        category: [],
        template_download_url: '',
        linked_product: '',
        template_usage: 0,
        edited_at: ''
      };
      this.$store.commit('templates/setSiteId', this.$route.params.siteId);
      await this.$store.dispatch('templates/getDesignLivePaths');
      this.products = this.isSiteDefault ? this.$store.getters['products/getProducts'] : await this.$store.dispatch('products/getProductsBySiteID', this.siteId);
      await Promise.all([this.addCheckboxes(), this.$store.dispatch('template_types/getAllTemplateTypes'), this.$store.dispatch('template_categories/getAllTemplateCategories')]);
      if (this.templateId) {
        await this.getTemplateByID();
      }
      this.validateCheckboxes();
    },

    async addCheckboxes() {
      this.$store.dispatch('showLoader');
      try {
        this.checkBoxes = await this.$store.dispatch('products/getOrderProcesses');
        this.checkBoxes.forEach(process => (this.template[process.key] = false));
      } catch (error) {
        console.error('addCheckboxes error', error);
        this.showAlert('Could not initialize the template form.');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },

    validateCheckboxes() {
      this.isAtLeastOneChecked = Object.entries(this.template).filter(([key, value]) => this.checkBoxes.map(({ key }) => key).includes(key) && value).length;
    },

    async getTemplateByID() {
      this.$store.dispatch('showLoader');
      try {
        this.template = await this.$store.dispatch('templates/getTemplateByID', this.templateId);
        this.currentTemplateName = this.template.name;
        console.log('Current template: ', this.template);
      } catch (error) {
        console.error('[getTemplateByID error ] ', error);
        this.showAlert('error', error.message || error || 'Could not load Template data.');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },

    async saveTemplate() {
      this.$store.dispatch('showLoader');
      const functionName = this.templateId ? 'editTemplate' : 'addTemplate';
      this.template.edited_at = await this.$store.dispatch('products/getCurrentDate');
      const templateObject = {
        id: this.templateId || '',
        data: this.template
      };
      try {
        if (this.uploadedFrontImage) {
          templateObject.data.preview_front_url = await this.$store.dispatch('templates/uploadTemplateImage', { name: this.template.name, file: this.uploadedFrontImage });
        }
        if (this.uploadedBackImage) {
          templateObject.data.preview_back_url = await this.$store.dispatch('templates/uploadTemplateImage', { name: this.template.name, file: this.uploadedBackImage });
        }

        const result = await this.$store.dispatch(`templates/${functionName}`, templateObject);

        if (result) {
          this.setSnackbar('success', `Template saved with id: ${result}`);
          console.log(`Template saved with id: ${result}`);
          this.continueEditing(result);
        }
      } catch (error) {
        console.error('[saveTemplate error ] ', error);
        this.showAlert('error', error.message || error || 'Could not save Template.');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },

    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    },

    handlePreviewUploadedFrontImage(files) {
      const [image] = files;
      this.uploadedFrontImage = image;
      this.template.preview_front_url = URL.createObjectURL(image);
    },

    handlePreviewUploadedBackImage(files) {
      const [image] = files;
      this.uploadedBackImage = image;
      this.template.preview_back_url = URL.createObjectURL(image);
    },

    handleClearUploadedImage(isFront = true) {
      if (isFront) {
        this.template.preview_front_url = '';
        this.uploadedFrontImage = null;
        return;
      }
      this.template.preview_back_url = '';
      this.uploadedBackImage = null;
    },

    back() {
      this.$router.back();
    },
    continueEditing(template_firebase_id) {
      if (this.$route.name === 'templatesNew') {
        const siteId = this.$route.params.siteId;
        this.$router.replace({ name: 'templatesEdit', params: { siteId, templateId: template_firebase_id } });
      }
      this.currentTemplateName = this.template.name;
    }
  }
};
</script>
