<template>
  <v-container fluid>
    <v-dialog v-model="isShippingAddressDialogOpen" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline font-weight-regular grey--text text--darken-2">Edit From Address</v-card-title>
        <v-card-text class="mt-5">
          <v-row v-for="([addressProperty, addressValue], index) in Object.entries(shippingAddress)" :key="index" class="justify-center mt-n6">
            <v-col cols="12">
              <v-text-field
                :key="index"
                v-model="shippingAddress[addressProperty]"
                :label="capitalize(addressProperty)"
                :value="addressValue"
                :rules="checkZipCode(addressProperty)"
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-n7 justify-end">
          <v-btn text class="mr-2" color="grey darken-3" @click="toggleShippingAddressDialog">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isFouroverResultVisible" max-width="500" persistent>
      <v-card>
        <v-card-title class="justify-end">
          <v-btn icon color="grey darken-2" class="mr-n1" @click="closeFouroverResultDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center mt-n5 mb-1">
            <v-icon :color="statusIcon.color" size="60">{{ statusIcon.text }}</v-icon>
          </v-row>
          <v-row class="justify-center mt-4 mb-1">
            <span v-if="isFouroverStatusSuccess" class="headline font-weight-medium green--text text--darken-2">Success</span>
            <span v-else class="headline font-weight-medium red--text text--darken-2">Failed</span>
          </v-row>
          <v-row class="justify-center fourover-result-message">
            <p class="title font-weight-medium grey--text text--darken-2 my-2">
              {{ fouroverResultMessage }}
            </p>
          </v-row>
          <v-row v-if="!isFouroverStatusSuccess" class="justify-center mt-3 mb-n2">
            <v-btn color="orange darken-2 white--text" block @click="createOrder"> <v-icon class="mr-2">mdi-restore</v-icon>Retry </v-btn>
          </v-row>
          <v-row v-if="isFouroverStatusSuccess" class="justify-center my-3">
            <v-btn block color="blue-grey darken-2" class="white--text" @click="returnToOsprey()">
              <v-img class="mr-4" src="@/assets/logos/ospreyLogo.png" max-width="22" height="30"></v-img>
              Return to Osprey
            </v-btn>
          </v-row>
          <v-row v-if="isFouroverStatusSuccess" class="mb-n2">
            <v-btn block color="primary" @click="viewOrderInStamp()">View Order Details</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card class="mb-0" elevation="4">
      <p class="title font-weight-regular pt-4 pl-3">Create 4over Order</p>
      <v-stepper v-model="steps">
        <v-stepper-header>
          <v-stepper-step :complete="steps > 1" step="1">Product Options</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="steps > 2" step="2">Shipping Address</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="steps > 3" step="3">Shipping Options</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="4">Artwork</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="optionsForm">
              <v-card class="elevation-0">
                <v-card-text>
                  <v-container>
                    <v-row class="justify-center">
                      <v-col cols="4">
                        <p class="subtitle-1 mt-1 mr-5 text-right">{{ runSize?.name }}</p>
                      </v-col>
                      <v-col cols="4">
                        <v-select v-model="selectedMainOptions[runSize?.field_name]" :items="runSize?.options" outlined dense :rules="optionRule"></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="justify-center">
                      <v-col cols="4">
                        <p class="subtitle-1 mt-1 mr-5 text-right">{{ colorSpec?.name }}</p>
                      </v-col>
                      <v-col cols="4">
                        <v-select v-model="selectedMainOptions[colorSpec?.field_name]" :items="colorSpec?.options" outlined dense :rules="optionRule"></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="justify-center">
                      <v-col cols="4">
                        <p class="subtitle-1 mt-1 mr-5 text-right">{{ turnaroundTime.name }}</p>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          v-model="selectedMainOptions[turnaroundTime.field_name]"
                          :items="turnaroundTime.options"
                          outlined
                          dense
                          :persistent-hint="!selectedMainOptions[turnaroundTime.field_name]"
                          :disabled="isTurnaroundSelectDisabled"
                          :rules="optionRule"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row v-for="(aog, index) in additionalOptionGroups" :key="index" class="justify-center">
                      <v-col cols="4">
                        <p class="subtitle-1 mt-1 mr-5 text-right">{{ aog.name }}</p>
                      </v-col>
                      <v-col cols="4">
                        <v-select v-model="selectedOptions[index]" :items="aog.options" outlined dense :rules="optionRule"></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-row class="justify-center mb-2">
                  <v-btn color="primary" @click="setProductOptions">Continue</v-btn>
                </v-row>
              </v-card>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card class="elevation-0">
              <v-card-text>
                <v-alert v-if="missingFields.length" type="error" color="orange darken-3" dismissible>
                  <span>The following required fields are missing from the address : {{ missingFields.toString() }}</span>
                </v-alert>

                <v-container>
                  <v-row class="justify-center">
                    <v-col cols="6">
                      <v-row class="mt-n3 mb-3">
                        <p class="subtitle-1 font-weight-bold grey--text text--darken-3">Shipping Address:</p>
                        <v-tooltip bottom open-delay="300">
                          <template v-slot:activator="{ on }">
                            <v-btn class="ml-6" color="blue-grey darken-1" outlined small v-on="on" @click="toggleShippingAddressDialog">
                              <v-icon class="mr-2">mdi-pencil</v-icon>Edit Address
                            </v-btn>
                          </template>
                          <span>Edit Shipping Address</span>
                        </v-tooltip>
                      </v-row>

                      <v-row v-for="(addrInfo, index) in Object.entries(shippingAddress)" :key="index" class="justify-start mt-n2 ml-9">
                        <v-icon color="primary" class="ml-n2 mr-2 mt-n4">mdi-chevron-right</v-icon>
                        <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                          {{ capitalize(addrInfo[0]) }}:
                          <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1">{{ addrInfo[1] }}</span>
                        </p>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-row class="justify-center mb-2">
                <v-btn color="primary" class="mr-8" outlined @click="goToStep(1)">Back</v-btn>
                <v-btn color="primary" class="ml-8" :disabled="!isShippingAddressValid" @click="getShippingOptions">Continue</v-btn>
              </v-row>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card class="elevation-0">
              <v-card-text>
                <v-container>
                  <v-row class="justify-center">
                    <v-col cols="4">
                      <p class="subtitle-1 mt-1 mr-5 text-right">Shipping Method</p>
                    </v-col>
                    <v-col cols="4">
                      <v-select v-model="selectedShippingOption" :items="shippingOptions" item-text="shipping_method" return-object outlined dense :rules="optionRule"></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-row class="justify-center mb-2">
                <v-btn color="primary" outlined @click="goToStep(2)">Back</v-btn>
                <v-btn color="primary" class="ml-8" :disabled="!isShippingMethodSelected" @click="goToStep(4)">Continue</v-btn>
              </v-row>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-card class="elevation-0">
              <v-card-text>
                <artwork-step :firebaseOrderId="firebaseOrderId" :orderObject="orderObjectForArtwork" />
              </v-card-text>
              <v-row class="justify-center mb-2">
                <v-btn color="primary" class="mr-8" outlined @click="goToStep(3)">Back</v-btn>
                <v-btn color="red darken-2 white--text mr-2" :disabled="!isArtworkUploaded" @click="createOrder"> <v-icon color="white" class="mr-2">mdi-send</v-icon>Send </v-btn>
              </v-row>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import FourOverArtworkStep from './FourOverArtworkStep.vue';

export default {
  components: {
    'artwork-step': FourOverArtworkStep
  },
  props: ['firebaseOrderId', 'dropQuantity', 'ospreyOrderId', 'mailDropId', 'order'],
  data() {
    return {
      steps: 1,
      selectedMainOptions: {
        colorspec_uuid: '',
        runsize_uuid: '',
        turnaround_uuid: ''
      },
      isInitialLoad: {
        runsize: true,
        colorspec: true,
        turnaround: true
      },
      selectedOptions: [],
      optionRule: [v => !!v || 'Please select an option.'],
      requiredShippingFields: ['email', 'phone', 'address', 'city', 'state', 'zipcode', 'country'],
      isShippingAddressDialogOpen: false,
      shippingAddress: {
        company: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: ''
      },
      shipFrom: {
        company: 'Growmail.com',
        firstname: 'Print',
        lastname: 'Production',
        email: 'cs@growmail.com',
        phone: '1-844-222-4769',
        address: 'East Spruce St.',
        address2: '425',
        city: 'Tarpon Springs',
        state: 'FL',
        zipcode: '34689',
        country: 'US'
      },
      zipCodeRule: [
        v => !!v || `ZIP code is Required`,
        v => (v && !isNaN(v)) || `ZIP code must contain only numbers!`,
        v => (v && v.length === 5) || `ZIP code must be 5 numbers!`
      ],
      shippingOptions: [],
      selectedShippingOption: {},
      isFouroverResultVisible: false,
      fouroverResult: {},
      fouroverResultMessage: '',
      turnaroundTime: {}
    };
  },
  computed: {
    ...mapGetters('fourover', {
      additionalOptionGroups: 'getAdditionalOptionGroups',
      mainOptionGroups: 'getMainOptionGroups',
      productInfo: 'getProductInfo',
      artworkData: 'getArtworkData'
    }),
    runSize() {
      return this.mainOptionGroups.filter(group => group.field_name === 'runsize_uuid')[0];
    },
    colorSpec() {
      return this.mainOptionGroups.filter(group => group.field_name === 'colorspec_uuid')[0];
    },
    isTurnaroundSelectDisabled() {
      return !this.selectedMainOptions.runsize_uuid || !this.selectedMainOptions.colorspec_uuid;
    },
    missingFields() {
      const missingFields = [];
      this.requiredShippingFields.forEach(field => {
        if (!this.shippingAddress[field]) {
          missingFields.push(`[ ${this.capitalize(field)} ]`);
        }
      });
      if (!this.shippingAddress.company && !(this.shippingAddress.firstname && this.shippingAddress.lastname)) {
        missingFields.push('[ Company OR (Firstname & Lastname) ]');
      }
      return missingFields;
    },
    isShippingAddressZipCodeValid() {
      return this.shippingAddress?.zipcode?.length === 5 && !isNaN(this.shippingAddress?.zipcode);
    },
    isShippingAddressValid() {
      return this.isShippingAddressZipCodeValid && !this.missingFields.length;
    },
    isShippingMethodSelected() {
      const shippingOptionValues = Object.values(this.selectedShippingOption);
      return shippingOptionValues.length === 2 && !shippingOptionValues.includes('');
    },
    orderObjectForArtwork() {
      const orderForArtwork = this.order;
      orderForArtwork.product = this.order.orderData.product;
      return orderForArtwork;
    },
    isFouroverStatusSuccess() {
      const successStatus = 'Success';
      return this.fouroverResult?.order_status?.toUpperCase() === successStatus.toUpperCase();
    },
    isArtworkUploaded() {
      return !!(this.artworkData.fr || this.artworkData.bk);
    },
    statusIcon() {
      return {
        color: this.isFouroverStatusSuccess ? 'green' : 'red',
        text: this.isFouroverStatusSuccess ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'
      };
    }
  },
  watch: {
    selectedMainOptions: {
      handler(value) {
        this.turnaroundTime = this.mainOptionGroups.filter(group => group.field_name === 'turnaround_uuid').map(obj => ({ ...obj }))[0];
        this.turnaroundTime.options = this.turnaroundTime.options.filter(option => option.runsize_uuid === value.runsize_uuid && option.colorspec_uuid === value.colorspec_uuid);
      },
      deep: true,
      immediate: true
    },
    runSize: {
      handler(newRunSize) {
        if (this.isInitialLoad?.runsize && newRunSize?.options?.length) {
          if (this.dropQuantity) {
            const matchingRunSize = newRunSize.options.find(option => option.text === this.dropQuantity);
            if (matchingRunSize) {
              this.selectedMainOptions.runsize_uuid = matchingRunSize.value;
            }
          }
          this.isInitialLoad.runsize = false;
        }
      },
      immediate: true,
      deep: true
    },

    colorSpec: {
      handler(newColorSpec) {
        if (this.isInitialLoad?.colorspec && newColorSpec?.options?.length) {
          const fourOverOption = newColorSpec.options.find(option => option.text.startsWith('4/4'));
          this.selectedMainOptions.colorspec_uuid = fourOverOption ? fourOverOption.value : newColorSpec.options[0].value;
          this.isInitialLoad.colorspec = false;
        }
      },
      immediate: true,
      deep: true
    },

    turnaroundTime: {
      handler(newTurnaroundTime) {
        if (this.isInitialLoad?.turnaround && newTurnaroundTime?.options?.length) {
          const matchingTurnaround = newTurnaroundTime.options.find(option => option.text === this.order.orderData.delivery.turnaround);
          matchingTurnaround
            ? (this.selectedMainOptions.turnaround_uuid = matchingTurnaround.value)
            : (this.selectedMainOptions.turnaround_uuid = newTurnaroundTime.options[0].value);
          this.isInitialLoad.turnaround = false;
        }
      },
      immediate: true,
      deep: true
    },

    shippingOptions: {
      handler(newShippingOptions) {
        const matchingShipping = newShippingOptions.find(option => option.shipping_method === this.order.orderData.delivery.name);
        if (matchingShipping) {
          this.selectedShippingOption = matchingShipping;
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getShippingAddressData();
  },
  methods: {
    goToStep(step) {
      this.steps = step;
    },
    setProductOptions() {
      if (this.$refs.optionsForm.validate()) {
        Object.entries(this.selectedMainOptions).forEach(([field, value]) => this.$store.commit('fourover/setProductInfoField', { field, value }));
        this.$store.commit('fourover/setProductInfoField', { field: 'option_uuids', value: this.selectedOptions });
        this.goToStep(2);
      }
    },
    getShippingAddressData() {
      const customerData = this.order.orderData.customer;
      const customerInfo = customerData.additional;
      const customerLocation = customerData.customer_location;
      this.shippingAddress = {
        company: customerInfo?.company_name || '',
        firstname: customerInfo?.first_name || '',
        lastname: customerInfo?.last_name || '',
        email: customerInfo?.email || '',
        phone: customerInfo?.phone || '8442224769',
        address: customerLocation?.street || '',
        address2: customerLocation?.apartment || '',
        city: customerLocation?.city || '',
        state: customerLocation?.state || '',
        zipcode: this.padShippingZip(customerLocation?.zip_code),
        country: customerLocation?.country || 'US'
      };
    },
    padShippingZip(zip) {
      return zip?.toString().padStart(5, '0') || '';
    },
    toggleShippingAddressDialog() {
      this.isShippingAddressDialogOpen = !this.isShippingAddressDialogOpen;
    },
    checkZipCode(field) {
      return field === 'zipcode' ? this.zipCodeRule : [];
    },
    async getShippingOptions() {
      try {
        this.$store.dispatch('showLoader', { message: 'Loading shipping options ...' });
        const shippingQuotePayload = { product_info: this.productInfo, shipping_address: this.shippingAddress };
        this.shippingOptions = await this.$store.dispatch('fourover/fetchShippingOptions', shippingQuotePayload);
        this.goToStep(3);
      } catch (error) {
        console.error('[getShippingOptions ERROR ]', error.response?.data || error);
        this.$store.dispatch('showAlert', { type: 'error', message: error.response?.data?.message || error?.message || 'Could not load shipping options.' });
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    getArtworkFields() {
      const artworkFiles = {};
      if (this.artworkData.fr) {
        artworkFiles.fr = this.artworkData.fr;
      }
      if (this.artworkData.bk) {
        artworkFiles.bk = this.artworkData.bk;
      }
      return artworkFiles;
    },
    getJobObject() {
      return {
        ...this.productInfo,
        job_name: `${this.ospreyOrderId}-${this.mailDropId}`,
        ship_to: this.shippingAddress,
        ship_from: this.shipFrom,
        shipper: this.selectedShippingOption,
        dropship: true,
        files: this.getArtworkFields()
      };
    },
    getFouroverOrderObject() {
      const job = this.getJobObject();
      return {
        order_id: this.firebaseOrderId,
        is_test_order: !this.$isProductionEnv,
        skip_confirmation: true,
        jobs: [job],
        meta: {
          osprey_order_id: this.ospreyOrderId,
          osprey_mail_order_id: this.mailDropId
        }
      };
    },
    async createOrder() {
      try {
        this.$store.dispatch('showLoader', { message: 'Submitting order to 4over ...' });
        const fouroverOrder = this.getFouroverOrderObject();
        console.log('[ 4over order object ]', fouroverOrder);
        this.fouroverResult = await this.$store.dispatch('fourover/createOrder', fouroverOrder);
        const [jobResult] = Object.values(this.fouroverResult.job_ids);
        this.fouroverResultMessage = jobResult.messages?.description || '';
        this.isFouroverResultVisible = true;
      } catch (error) {
        console.error('[ createOrder ERROR ]', error.response?.data || error);
        this.$store.dispatch('showAlert', { type: 'error', message: error.response?.data?.message || error?.message || 'Something went wrong while creating the order.' });
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    capitalize(string) {
      return `${string[0].toUpperCase()}${string.slice(1)}`;
    },
    closeFouroverResultDialog() {
      this.isFouroverResultVisible = false;
    },
    viewOrderInStamp() {
      this.$router.push({
        path: `/orders/view/${this.firebaseOrderId}`
      });
    },
    returnToOsprey() {
      window.open(`${process.env.VUE_APP_OSPREY_ENDPOINT}/orders/view.cfm?id=${this.ospreyOrderId}`);
    }
  }
};
</script>

<style scoped>
.fourover-result-message {
  overflow-wrap: anywhere;
}
</style>
