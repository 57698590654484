export function parseFormattedDateString(dateStr) {
  if (!dateStr) return new Date(0);

  const [datePart, timePart] = dateStr.split(', ');
  if (!datePart || !timePart) return new Date(0);

  const [month, day, year] = datePart.split('/');
  const [hours, minutes, seconds] = timePart.split(':');

  return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10), parseInt(hours, 10), parseInt(minutes, 10), parseInt(seconds, 10));
}

export function sortDateStrings(a, b) {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;

  const dateA = parseFormattedDateString(a);
  const dateB = parseFormattedDateString(b);

  return dateA - dateB;
}

export function formatDateToString(date) {
  if (!date || !(date instanceof Date) || isNaN(date)) {
    return '';
  }

  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${month}/${day}/${year}, ${hours}:${minutes}:${seconds}`;
}
