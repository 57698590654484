<template>
  <v-container>
    <v-row class="mt-n4 mb-4">
      <v-toolbar flat class="site-table--header">
        <v-toolbar-title class="body-2 mr-2">Status</v-toolbar-title>
        <v-chip-group v-model="selectedStatus" active-class="primary--text" mandatory>
          <template v-for="(filterName, index) in statusFilterItems">
            <v-chip :key="index" filter outlined>
              <span>{{ filterName }}</span>
            </v-chip>
          </template>
        </v-chip-group>
      </v-toolbar>
    </v-row>
    <stamp-data-table :headers="headers" :data="drops" item-key="mail_drop_id" :search="search" sort-by="drop_date" :is-loading="isFouroverOrdersLoading">
      <template #[`item.firebase_order_id`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon v-on="on" @click="copyItemToClipboard(item.firebase_order_id)"><v-icon color="amber darken-3">mdi-firebase</v-icon></v-btn>
          </template>
          <span>Copy FirebaseID to clipboard [ {{ item.firebase_order_id }} ]</span>
        </v-tooltip>
      </template>
      <template #[`item.action`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon color="blue darken-1" @click="openItem(item)" v-on="on">mdi-arrow-right-bold-circle-outline</v-icon>
          </template>
          <span>{{ actionButtonText }}</span>
        </v-tooltip>
      </template>
    </stamp-data-table>
  </v-container>
</template>
<script>
import DataTable from '../../common/DataTable.vue';
import orderService from '@/services/orderService.js';

export default {
  components: { 'stamp-data-table': DataTable },
  props: ['search'],
  data() {
    return {
      fourOverOrders: [],
      drops: [],
      headers: [
        { text: 'Drop ID', value: 'mail_drop_id', sortable: true },
        { text: 'Drop Date', value: 'drop_date', sortable: true, align: 'center' },
        { text: 'Drop Quantity', value: 'qty', sortable: true },
        { text: 'Osprey Order ID', value: 'osprey_order_id', sortable: true },
        { text: 'Osprey Customer ID', value: 'crm_customer_id', sortable: true },
        { text: 'Order Total', value: 'total_print_price', sortable: true, align: 'center' },
        { text: 'Fourover Payment', value: 'fourover_price', sortable: true, align: 'center' },
        { text: 'Fourover Job ID', value: 'fourover_job_id', sortable: true, align: 'center' },
        { text: 'Firebase Order ID', value: 'firebase_order_id', sortable: false, align: 'center' },
        { text: 'Action', value: 'action', align: 'center', sortable: false }
      ],
      statusFilterItems: ['Initial', 'Launched'], //[0, 1]
      selectedStatus: 0,
      isFouroverOrdersLoading: false
    };
  },
  computed: {
    actionButtonText() {
      return this.selectedStatus ? 'View Order Details' : 'Send to 4over';
    }
  },
  watch: {
    selectedStatus() {
      this.getFourOverOrders();
    }
  },
  created() {
    this.getFourOverOrders();
  },
  methods: {
    async getFourOverOrders() {
      this.isFouroverOrdersLoading = true;
      try {
        const response = await this.$store.dispatch('fourover/getFourOverOrders');

        console.log(`Fetched ${response.data.length} orders`, response.data);

        const filteredData = response.data.filter(item => {
          return !!item.Order?.fourover_product_id && (this.selectedStatus ? !!item.fourover_job_id : !item.fourover_job_id);
        });

        this.drops = filteredData.map(item => ({
          mail_drop_id: item.mailOrderID,
          drop_date: item.ActualMailDate || item.EstimatedMailDate || '',
          qty: item.actualPieces || item.quantity || 0,
          osprey_order_id: item.orderID,
          crm_customer_id: item.Order?.CustomerID || '',
          total_print_price: item.Order?.oPrintPrice?.toFixed(2) || '0.00',
          fourover_price: item.fourover_payment_amount?.toFixed(2) || '',
          fourover_job_id: item.fourover_job_id || '',
          firebase_order_id: item.Order?.firebase_order_id || ''
        }));
      } catch (error) {
        console.error('Getting 4over orders error:', error);
        this.$store.dispatch('showAlert', { type: 'error', message: 'Could not load orders for 4over. Please try again later.' });
      } finally {
        this.isFouroverOrdersLoading = false;
      }
    },
    openItem(drop) {
      const path = this.selectedStatus
        ? `/orders/view/${drop.firebase_order_id}`
        : `?firebase_order_id=${drop.firebase_order_id}&osprey_order_id=${drop.osprey_order_id}&mail_drop_id=${drop.mail_drop_id}&quantity=${drop.qty}`;
      this.$router.push({ path });
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    }
  }
};
</script>
