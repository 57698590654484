<template>
  <div class="mb-2">
    <v-row class="mt-4 pl-3">
      <p class="headline primary--text">Design Service Pricing - 4over Products</p>
    </v-row>

    <v-row v-if="!isLoading" class="mt-4 pl-3">
      <v-btn color="primary" small class="mb-2" :disabled="isLoading" @click="addDesignPriceRow">
        <v-icon left>mdi-plus</v-icon>
        Add Row
      </v-btn>
    </v-row>

    <v-row class="mt-4 pl-3 mb-4" style="width: 50%">
      <v-progress-circular v-if="isLoading" indeterminate size="32" color="primary"></v-progress-circular>
      <stamp-data-table v-else v-model="designPrices" :data="designPrices" :headers="markupPriceHeaders" :is-footer-hidden="true" item-key="id" style="width: 100%">
        <template #[`item.range`]="{ item }">
          <div class="d-flex justify-center align-center py-2">
            <v-text-field
              v-model.number="item.min"
              type="number"
              dense
              outlined
              class="count-input text-center"
              placeholder="Min"
              hide-details
              disabled
              @blur="validateRange(item)"
            ></v-text-field>
            <span class="mx-3 subtitle-1">-</span>
            <v-text-field
              v-model.number="item.max"
              type="number"
              dense
              outlined
              class="text-center"
              placeholder="Max"
              hide-details
              :disabled="!item.max"
              @blur="validateRange(item)"
            ></v-text-field>
          </div>
        </template>
        <template #[`item.price`]="{ item }">
          <v-text-field v-model.number="item.price" prefix="$" type="number" :min="0" :max="100" dense outlined hide-details @blur="emitDesignPrices(item)"></v-text-field>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon small :disabled="isDeleteRowDisabled" class="mr-2" @click="deleteDesignPriceRow(item)"> mdi-delete </v-icon>
        </template>
      </stamp-data-table>
    </v-row>

    <v-alert type="info" outlined class="pl-3" style="width: 50%">
      <ul class="mb-0">
        <li>Design prices are determined based on the <strong>product's area in square inches</strong>, calculated as width × height.</li>
        <li>For example, if a product measures 6×4 inches, its total area would be 24 square inches.</li>
        <li>The price is assigned based on the corresponding range.</li>
        <li>
          These price ranges are continuous intervals, meaning the square inch value must be <strong>greater than or equal to the minimum</strong> value and
          <strong>less than the maximum</strong> value in a given range.
        </li>
      </ul>
    </v-alert>
  </div>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';

export default {
  name: 'fouroverDesignPrices',
  components: {
    'stamp-data-table': DataTable
  },
  emits: ['on-design-prices-changed'],
  data() {
    return {
      designPrices: [],
      markupPriceHeaders: [
        { text: 'Square inch ranges (size_x * size_y)', value: 'range', sortable: false, width: '300' },
        { text: 'Design Price', value: 'price', sortable: false, align: 'center' },
        { text: '', value: 'actions', sortable: false, align: 'center' }
      ],
      isLoading: false
    };
  },
  computed: {
    isDeleteRowDisabled() {
      return this.designPrices.length === 1;
    },
    selectedSite() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getFouroverDesignPrices();
  },
  methods: {
    emitDesignPrices() {
      const formattedPrices = this.designPrices.map(item => ({
        service_name: 'Custom Design',
        from_sq_inch: item.min,
        up_to_sq_inch: item.max || 9999,
        price: item.price,
        site_id: this.selectedSite
      }));

      this.$emit('on-design-prices-changed', formattedPrices);
    },
    addDesignPriceRow() {
      const lastRow = this.designPrices[this.designPrices.length - 1];
      const lastRowIndex = this.designPrices.length - 1;

      if (lastRowIndex >= 0) {
        if (lastRow.min && !lastRow.max) {
          lastRow.max = (lastRow.min + 1) * 2 - 1;
        }
        if (!lastRow.min && !lastRow.max) {
          lastRow.max = 999;
        }

        this.designPrices.push({
          min: this.designPrices[this.designPrices.length - 1].max + 1,
          price: this.designPrices[this.designPrices.length - 1].price || 50
        });
      } else {
        this.designPrices.push({
          min: 0,
          price: 50
        });
      }
      this.validateRange(null, true);
      this.emitDesignPrices();
    },
    validateRange(item, validateAll = false) {
      if (!this.designPrices.length) return;

      const validateItem = (currentItem, index) => {
        const isFirst = index === 0;
        const isLast = index === this.designPrices.length - 1;
        const prev = !isFirst ? this.designPrices[index - 1] : null;
        const next = !isLast ? this.designPrices[index + 1] : null;

        if (isFirst) {
          currentItem.min = 0;
        }

        if (prev?.max) {
          currentItem.min = prev.max;
        }

        if (isLast) {
          delete currentItem.max;
        } else {
          if (!currentItem.max || currentItem.max <= currentItem.min) {
            currentItem.max = currentItem.min + 1;
          }

          if (next) {
            next.min = currentItem.max;
          }
        }
      };

      if (validateAll) {
        this.designPrices.forEach((currentItem, index) => {
          validateItem(currentItem, index);
        });
      } else {
        const index = this.designPrices.indexOf(item);
        validateItem(item, index);

        for (let i = index + 1; i < this.designPrices.length; i++) {
          validateItem(this.designPrices[i], i);
        }
      }

      this.emitDesignPrices();
    },
    deleteDesignPriceRow(item) {
      const index = this.designPrices.findIndex(row => row.max === item.max && row.min === item.min);

      if (index > -1) {
        this.designPrices.splice(index, 1);
      }

      if (!this.designPrices.length) {
        return;
      }

      if (index === 0) {
        this.designPrices[0].min = 0;
      }

      if (index === this.designPrices.length) {
        delete this.designPrices[this.designPrices.length - 1].max;
      }

      this.validateRange(null, true);
      this.emitDesignPrices();
    },
    async getFouroverDesignPrices() {
      this.isLoading = true;
      try {
        const result = await this.$store.dispatch('product_pricing/getFouroverDesignPrices', {
          site_id: this.selectedSite || 'default'
        });

        if (result?.length) {
          this.designPrices = result.map(item => ({
            id: item.id,
            min: item.from_sq_inch,
            max: item.up_to_sq_inch === 9999 ? undefined : item.up_to_sq_inch,
            price: item.price,
            service_name: item.service_name
          }));
        } else {
          this.designPrices = [
            { min: 0, max: 5, price: 50 },
            { min: 5, max: 15, price: 125 },
            { min: 15, max: 30, price: 175 },
            { min: 30, price: 225 }
          ];
        }

        this.validateRange(null, true);
        this.emitDesignPrices();
      } catch (error) {
        console.error('Error fetching design prices:', error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.w-100 {
  width: 100%;
}

.table-overlay {
  border-radius: 4px;
}
</style>
